import React, { useState, useEffect } from "react";
import { Spring } from "react-spring/renderprops.cjs";
import styles from "./counter.module.scss";

function Digit({ dNew, ...props }) {
  return (
    <li className={styles.digit}>
      <div className={styles.line} />
      <span className={styles.front}>{dNew}</span>
      <div className={styles.hinge_wrap}>
        <div className={styles.hinge}>
          <span className={styles.back}>{dNew}</span>
        </div>
      </div>
    </li>
  );
}

export default Digit;
