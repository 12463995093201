import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styles from "./header.module.scss";

const Header = () => (
  <header className={styles.header}>
    <Link className={styles.logo} to="/">
      <img src="/fh-logo.svg" alt="Fagbevægelsens Hovedorganisation" />
    </Link>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
