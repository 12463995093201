/**
 * Viewport Assertion
 * Asserts the user Viewport
 */

export function assertViewport() {
  if (typeof window !== `undefined`) {
    const vp = window.innerWidth
    if (vp < 600) {
      return 'DEFAULT'
    } else if (vp < 1024) {
      return 'MEDIUM'
    } else if (vp < 1366) {
      return 'LARGE'
    } else {
      return 'MAXWIDTH'
    }
  }
}

/**
 * Viewport tester
 * @param {String} viewport
 * @param {Array} match
 */

export function isViewport(viewport, match) {
  return match.find(x => x === viewport)
}

/**
 * Get scrollY position
 * @param {String} viewport
 * @param {Array} match
 */

export function scrollY() {
  if (typeof window !== 'undefined') {
    return window.pageYOffset || document.documentElement.scrollTop
  }
  return 0
}

/**
 * Element Partially In Viewport
 * @param {DOMElement} el The DOM Element to query
 */

export function elementVisible(el, factorBot, factorTop) {
  let rect = el.getBoundingClientRect()
  let bottomFactor = factorBot || 1
  let topFactor = factorTop || 1

  if (typeof window !== 'undefined') {
    return rect.bottom >= 0
      ? rect.bottom <=
          (window.innerHeight * bottomFactor ||
            document.documentElement.clientHeight * bottomFactor) ||
          rect.top <=
            (window.innerHeight * topFactor ||
              document.documentElement.clientHeight * topFactor)
      : false
  }
}

/**
 * Get innerHeight
 * @param {String} viewport
 * @param {Array} match
 */

export function assertInnerHeight() {
  if (typeof window !== 'undefined') {
    return window.innerHeight
  }
  return 0
}
