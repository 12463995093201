import React from "react";
import Helmet from "react-helmet";
import { detectIE11 } from "helpers";

function Scripts({ ...props }) {
  if (detectIE11()) return null;
  /* const pixel1 = `!function(f,b,e,v,n,t,s)
     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
     n.queue=[];t=b.createElement(e);t.async=!0;
     t.src=v;s=b.getElementsByTagName(e)[0];
     s.parentNode.insertBefore(t,s)}(window, document,'script',
     'https://connect.facebook.net/en_US/fbevents.js');
     fbq('init', '385730898671157');
     fbq('track', 'PageView');`;
  const pixel2 = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '438150053389797');
      fbq('track', 'PageView');`; */
  const analytics = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date());gtag('config', 'UA-136529641-1');`;
  return (
    <Helmet key="scripts">
      <script
        key="googletagmanager"
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-136529641-1"
        type="text/javascript"
      />
      <script key="analytics" type="text/javascript">
        {analytics}
      </script>
    </Helmet>
  );
}

/*
<script key="pixel1" type="text/javascript">
  {pixel1}
</script>
<script key="pixel2" type="text/javascript">
  {pixel2}
</script>
<noscript key="noscript1">{`<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=385730898671157&ev=PageView&noscript=1
https://www.facebook.com/tr?id=385730898671157&ev=PageView&noscript=1
"
/>`}</noscript>
<noscript key="noscript2">{`<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=438150053389797&ev=PageView&noscript=1
https://www.facebook.com/tr?id=438150053389797&ev=PageView&noscript=1
"
/>`}</noscript>
*/

export default Scripts;
