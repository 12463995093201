import React from "react";
import styles from "./section.module.scss";
import { assertViewport } from "helpers";

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: assertViewport()
    };
  }

  render() {
    const {
      gradient,
      red,
      darkRed,
      white,
      grey,
      full,
      bgImage,
      className,
      scale,
      noOverflow,
      equalPadding,
      ...props
    } = this.props;
    const cssClass = [styles.section];
    if (gradient) cssClass.push(styles.gradient);
    if (red) cssClass.push(styles.red);
    if (darkRed) cssClass.push(styles.dark_red);
    if (white) cssClass.push(styles.white);
    if (grey) cssClass.push(styles.grey);
    if (full) cssClass.push(styles.full);
    if (scale) cssClass.push(styles.withBg);
    if (noOverflow) cssClass.push(styles.noOverflow);
    if (equalPadding) cssClass.push(styles.equalPadding);
    if (className) cssClass.push(className);
    return (
      <section className={cssClass.join(" ")} {...props}>
        {props.children}
      </section>
    );
  }
}

export default Section;
