import React from "react";
import { Title, Icon } from "components";
import styles from "./footer.scss";

function Footer({ ...props }) {
  return (
    <footer className="footer-container" role="content-info">
      <div className="before-footer-wrapper">
        <div id="before-footer" className="widget-area before-footer">
          <aside id="text-2" className="widget widget_text">
            <h4 className="widgettitle">1,4 mio</h4>
            <div className="textwidget">
              <p>Medlemmer</p>
            </div>
          </aside>
          <aside id="text-3" className="widget widget_text">
            <h4 className="widgettitle">79</h4>
            <div className="textwidget">
              <p>medlemsorganisationer</p>
            </div>
          </aside>
          <aside id="text-4" className="widget widget_text">
            <div className="textwidget">
              <p>
                <strong>
                  Fælles talerør for fagforeninger med 1,4 mio. lønmodtagere
                </strong>
              </p>
              <p>Fagbevægelsens hovedorganisation</p>
            </div>
          </aside>
        </div>
      </div>

      <div className="footer-wrapper">
        <div id="footer" className="widget-area footer">
          <aside id="nav_menu-2" className="widget widget_nav_menu">
            <h4 className="widgettitle">Indsatsområder</h4>
            <div className="menu-footer-menu-1-container">
              <ul id="menu-footer-menu-1" className="menu">
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/category/arbejdsmiljoe/"
                    className="menu__link"
                  >
                    Arbejdsmiljø
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/category/arbejdsmiljoe/amr2019/"
                    className="menu__link"
                  >
                    AMR2019
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/category/beskaeftigelse/"
                    className="menu__link"
                  >
                    Beskæftigelse
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/category/eu-og-internationalt/"
                    className="menu__link"
                  >
                    EU og internationalt
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/category/uddannelse/"
                    className="menu__link"
                  >
                    Uddannelse
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/category/udvikling-af-den-offentlige-sektor/"
                    className="menu__link"
                  >
                    Udvikling af den offentlige sektor
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/category/arbejdsret-og-overenskomst/"
                    className="menu__link"
                  >
                    Ansættelsesret og overenskomst
                  </a>
                </li>
              </ul>
            </div>
          </aside>
          <aside id="nav_menu-3" className="widget widget_nav_menu">
            <h4 className="widgettitle">Andre genveje</h4>
            <div className="menu-footer-menu-2-container">
              <ul id="menu-footer-menu-2" className="menu">
                <li className="menu__item">
                  <a
                    href="https://fho.dk/lizettes-side/"
                    className="menu__link"
                  >
                    Lizettes Blog
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/type/nyhed/"
                    className="menu__link"
                  >
                    Nyheder
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/type/baggrund-og-dokumentation/"
                    className="menu__link"
                  >
                    Analyser
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/blog/type/hoeringssvar/"
                    className="menu__link"
                  >
                    Høringssvar
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/kontakt/presse/"
                    className="menu__link"
                  >
                    Presse
                  </a>
                </li>
                <li className="menu__item">
                  <a href="https://fho.dk/kontakt/" className="menu__link">
                    Kontakt
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/organisationsdiagram-2/"
                    className="menu__link"
                  >
                    Organisationsdiagram
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/om-fagbevaegelsens-hovedorganisation/ledige-stillinger/"
                    className="menu__link"
                  >
                    Ledige stillinger
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    href="https://fho.dk/om-fagbevaegelsens-hovedorganisation/privatlivspolitik/"
                    className="menu__link"
                  >
                    Privatlivspolitik
                  </a>
                </li>
                <li className="menu__item">
                  <a href="https://fho.dk/gdpr/" className="menu__link">
                    Cookie-politik
                  </a>
                </li>
              </ul>
            </div>
          </aside>
          <aside id="text-5" className="widget widget_text">
            <div className="textwidget">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 0 20px"
                }}
              >
                <a style={{ marginRight: "25px" }} href="#">
                  <img
                    style={{ margin: 0, display: "block" }}
                    role="img"
                    src="https://fho.dk/wp-content/uploads/2018/11/fh-footer.svg"
                    alt=""
                    width="83"
                  />
                </a>
                <br />
                <a style={{ marginRight: "25px" }} href="#">
                  <img
                    style={{ margin: 0, display: "block" }}
                    role="img"
                    src="https://fho.dk/wp-content/uploads/2018/11/ok-footer.svg"
                    alt=""
                    width="58"
                  />
                </a>
              </div>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: 900,
                  margin: "0 0 20px;"
                }}
              >
                Islands Brygge 32D · 2300 København S
              </p>
              <p style={{ fontSize: "14px", margin: "0 0 20px" }}>
                Telefon: <a href="tel:35 24 60 00">35 24 60 00</a> · EAN:
                5790002492211
                <br />
                Email: <a href="mailto:fh@fho.dk">fh@fho.dk</a> · CVR: 31432812
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 0 20px"
                }}
              >
                <a
                  style={{ marginRight: "15px" }}
                  href="https://www.facebook.com/FH_Danmark/"
                >
                  <img
                    style={{ margin: 0, display: "block" }}
                    role="img"
                    src="https://fho.dk/wp-content/uploads/2018/11/logo-facebook.svg"
                    alt=""
                    width="30"
                  />
                </a>
                <br />
                <a
                  style={{ marginRight: "15px" }}
                  href="https://twitter.com/FH_dk2019?lang=da"
                >
                  <img
                    style={{ margin: 0, display: "block" }}
                    role="img"
                    src="https://fho.dk/wp-content/uploads/2018/11/logo-twitter.svg"
                    alt=""
                    width="30"
                  />
                </a>
                <a
                  style={{ marginRight: "15px" }}
                  href="https://www.linkedin.com/company/fagbevaegelsenshovedorganisation/"
                >
                  <img
                    style={{ margin: 0, display: "block" }}
                    role="img"
                    src="https://fho.dk/wp-content/uploads/2018/11/logo-linkedin.svg"
                    alt=""
                    width="30"
                  />
                </a>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
