import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Header, SEO, Scripts } from "components";
import styles from "./layout.module.scss";
import "scss/global.scss";

if (typeof window !== "undefined") {
  // Make scroll behavior of internal links smooth
  require("smooth-scroll")('a[href*="#"]');
}
const Layout = ({ subTitle, description, isModalActive, noLogo, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => {
      const cssClass = [styles.main];
      if (isModalActive) cssClass.push(styles.modalActive);
      if (noLogo) cssClass.push(styles.noLogo);
      return (
        <div className={cssClass.join(" ")}>
          <SEO
            title={`${subTitle}`}
            description={description || data.site.siteMetadata.description}
          />
          <Scripts />
          {!noLogo && <Header />}
          {children}
        </div>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
