import React, { useRef } from "react";
import {
  useTransition,
  useSpring,
  useChain,
  animated
} from "react-spring/web.cjs";

function AnimTitle({ fadeText, typewriterText, delay, onRest, bold }) {
  // Build a spring and catch its ref
  const springRef = useRef();
  const values = {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  };
  const props = useSpring({
    ...values,
    ref: springRef,
    onRest: onRest ? onRest : () => {},
    delay: delay
  });
  // Build a transition and catch its ref
  const letters = typewriterText
    ? typewriterText.split("").map((x, i) => {
        return { t: x, key: i };
      })
    : undefined;
  const transitionRef = useRef();
  const transitions = letters
    ? useTransition(letters, letter => letter.key, {
        from: {
          visibility: "hidden"
        },
        enter: {
          visibility: "visible"
        },
        trail: 40,
        ref: transitionRef
      })
    : undefined;
  // First run the spring, when it concludes run the transition
  useChain(
    [springRef, transitionRef],
    [delay ? delay + 1 : 1, delay ? delay + 2 : 2]
  );
  // Use the animated props like always
  return (
    <span>
      {fadeText && (
        <>
          <animated.span ref={springRef} style={props}>
            {fadeText}
          </animated.span>
          <br />
        </>
      )}
      {transitions &&
        transitions.map(({ item, key, props }) =>
          bold ? (
            <animated.span key={key} style={props}>
              {item.t}
            </animated.span>
          ) : (
            <animated.span key={key} style={props}>
              {item.t}
            </animated.span>
          )
        )}
    </span>
  );
}

export default AnimTitle;
