import React from "react";
import styles from "./title.module.scss";

function Title({ as, large, html, narrow, red, noMargin, children, ...props }) {
  const cssClass = [styles.title];
  if (noMargin) cssClass.push(styles.noMargin);
  if (narrow) cssClass.push(styles.narrow);
  if (large) cssClass.push(styles.large);
  if (red) cssClass.push(styles.red);
  switch (as) {
    case "h1":
      return children ? (
        <h1 className={cssClass.join(" ")}>{children}</h1>
      ) : (
        <h1
          className={cssClass.join(" ")}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case "h3":
      return children ? (
        <h3 className={cssClass.join(" ")}>{children}</h3>
      ) : (
        <h3
          className={cssClass.join(" ")}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case "h4":
      return children ? (
        <h4 className={cssClass.join(" ")}>{children}</h4>
      ) : (
        <h4
          className={cssClass.join(" ")}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    default:
      return children ? (
        <h2 className={cssClass.join(" ")}>{children}</h2>
      ) : (
        <h2
          className={cssClass.join(" ")}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
  }
}

export default Title;
