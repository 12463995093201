import React from 'react'
import styles from './card.module.scss'

function Card({ children, noPad, style, ...props }) {
  const cssClass = [styles.card]
  if (noPad) cssClass.push(styles.noPad)
  return (
    <article style={style} className={cssClass.join(' ')}>
      {children}
    </article>
  )
}

export default Card
