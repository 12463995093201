import React, { useState, useEffect } from "react";
import { Spring } from "react-spring/renderprops.cjs";
import PropTypes from "prop-types";
import moment from "moment";
import Digit from "./Digit";
import styles from "./counter.module.scss";

function Counter({ delay, bare, ...props }) {
  const [number, setNumber] = useState(calcNumber());

  function calcNumber() {
    const startDate = moment("04/02/2019");
    const now = moment(new Date());
    const timeSinceStart = Math.floor(
      moment.duration(now.diff(startDate)).as("seconds")
    );
    const initialValue = 6220501551;
    const incrementPerSecond = 105.461;
    const value =
      initialValue + Math.floor(incrementPerSecond * timeSinceStart);
    return value;
  }

  useEffect(() => {
    if (number === 0) setNumber(calcNumber());
  });

  function getDigits(n) {
    return n
      .toString()
      .split("")
      .reverse();
  }

  function startTimer() {
    // setInitial(false);
    setTimeout(() => {
      setNumber(calcNumber());
      startTimer();
    }, 1000);
  }

  const cssClass = [styles.counter_wrapper];
  if (bare) cssClass.push(styles.bare);

  return (
    <div className={cssClass.join(" ")}>
      <Spring
        config={{
          mass: 1,
          tension: 100
        }}
        from={{
          number: 0
        }}
        to={{ number: number }}
        onRest={startTimer}
        delay={delay}
      >
        {props => {
          const initialNumbers = getDigits(Math.floor(props.number));
          return (
            <ul className={styles.flip_counter}>
              <Digit dNew={initialNumbers[9] || 0} />
              <li className={styles.separator}>.</li>
              <Digit dNew={initialNumbers[8] || 0} />
              <Digit dNew={initialNumbers[7] || 0} />
              <Digit dNew={initialNumbers[6] || 0} />
              <li className={styles.separator}>.</li>
              <Digit dNew={initialNumbers[5] || 0} />
              <Digit dNew={initialNumbers[4] || 0} />
              <Digit dNew={initialNumbers[3] || 0} />
              <li className={styles.separator}>.</li>
              <Digit dNew={initialNumbers[2] || 0} />
              <Digit dNew={initialNumbers[1] || 0} />
              <Digit dNew={initialNumbers[0] || 0} />
            </ul>
          );
        }}
      </Spring>
    </div>
  );
}

export default Counter;

Counter.defaultProps = {
  unit: ""
};

Counter.propTypes = {
  unit: PropTypes.string
};
