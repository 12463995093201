import React from 'react'
import styles from './content.module.scss'

function Content({ center, narrow, middle, children, className, ...props }) {
  const cssClass = [styles.content]
  if (center) cssClass.push(styles.center)
  if (narrow) cssClass.push(styles.narrow)
  if (middle) cssClass.push(styles.middle)
  if (className) cssClass.push(className)
  return <article className={cssClass.join(' ')}>{children}</article>
}

export default Content
