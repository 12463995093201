import React from "react";
import { withPrefix } from "gatsby";
import styles from "./icon.module.scss";

function Icon({ icon, size, className, ...props }) {
  const cssClass = [styles.icon];
  const style = {};
  if (className) cssClass.push(className);
  if (size) {
    style.width = `${size}px`;
    style.height = `${size}px`;
  }
  return (
    <svg className={cssClass.join(" ")} style={style}>
      <use xlinkHref={`/icons.svg#icon-${icon}`} />
    </svg>
  );
}

export default Icon;
