import React from 'react'
import { Card, Content, Title } from 'components'
import styles from './quote.module.scss'

function Quote({ title, children, center, ...props }) {
  const cssClass = []
  if (center) cssClass.push(styles.center)
  return (
    <Content className={cssClass.join(' ')}>
      <Card noPad style={{ marginBottom: '2.8rem' }}>
        <img src="/lizette-bg@3x.jpg" alt="Lizette Risgaard, Formand for FH" />
        <p
          style={{
            position: 'absolute',
            right: '10%',
            top: '50%',
            color: '#fff',
            zIndex: '10',
            transform: 'translateY(-50%)',
          }}
        >
          <strong>Lizette Risgaard</strong>
          <br />
          Formand for FH
        </p>
      </Card>
      <Title
        as="h3"
        html={`<strong>${title || 'Et vigtigt budskab'}</strong>`}
      />
      {children ? (
        children
      ) : (
        <p>
          <i>
            “Det går godt for dansk økonomi, men det går ikke godt for alle
            danskere. Velfærden smuldrer. Uligheden vokser. Det truer alt det,
            som har gjort Danmark til verdens bedste land: Velfærd, uddannelse
            og tryghed for alle. Vi skal i en anden retning. Danmark har brug
            for et ulighedsstop.”
          </i>
        </p>
      )}
      <p>
        <strong>Lizette Risgaard</strong>
        <br />
        Formand for FH
      </p>
    </Content>
  )
}

export default Quote
